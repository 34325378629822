import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import { SectionDescription } from "src/ui-treact/components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "src/ui-treact/components/misc/Layouts.js";
import ChevronDownIcon from "src/ui-treact/components/svgs/ChevronDownIcon.js";
// import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center text-black`;
const Heading = tw(SectionHeading)`w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center text-black`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-8 max-w-4xl relative`;
const FAQ = styled.div`

${tw`px-8 py-3 mt-3 text-gray-800 transition duration-300 rounded-lg cursor-pointer select-none sm:px-10 sm:py-3 hover:text-gray-900`}
background-color:#ecf4d96b;
&:hover{
  background-color:#ecf4d9
} 
`;  
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

// const DecoratorBlob1 = styled.div()`
//   ${tw`absolute top-0 right-0 w-56 h-56 text-teal-400 transform -translate-y-12 pointer-events-none -z-20 opacity-15 translate-x-2/3`}
// `;
// const DecoratorBlob2 = styled.div()`
//   ${tw`absolute bottom-0 left-0 w-64 h-64 transform pointer-events-none -z-20 opacity-15 -translate-x-2/3 text-primary-500`}
// `;



export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
       ...finalData
  }  
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {content.subheading && <Subheading>{content.subheading}</Subheading>}
            <Heading>{content.heading}</Heading>
            {content.description && <Description>{content.description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {content.faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      {/* <DecoratorBlob1/>
      <DecoratorBlob2 /> */}
    </Container>
  );
};
